<template>
    <div id="err">
      <div class="case">
        <div class="err">
          <div class="errLoad">
            <span class="sq1"></span>
            <span class="sq2"></span>
            <span class="sq3"></span>
            <span class="sq4"></span>
            <p>404</p>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
export default {
  name: '404'
}
</script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  #err {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgb(0, 0, 0);
  }

  #err .errLoad{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 200px;
    height: 200px;
  }

  #err .errLoad p{
    position: absolute;
    font-size: 7em;
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    animation: apparition 1s forwards;
  }

  .errLoad .sq {
    position: absolute;
    width: 210px;
    height: 120px;
    border: rgb(229, 101, 255) solid 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    cursor: pointer;
  }

  .errLoad .sq1 {
    position: absolute;
    width: 210px;
    height: 10px;
    transform: scaleX(0);
    background-color: rgb(154, 0, 185);
    top:15%;
    left: -2.5%;
    cursor: pointer;
    animation: sq1 0.2s 0.5s forwards;
    transform-origin: left 50%;
  }

  .errLoad .sq2 {
    position: absolute;
    width: 10px;
    height: 140px;
    transform: scaleX(0);
    background-color: rgb(154, 0, 185);
    top:15%;
    left: 102.5%;
    cursor: pointer;
    animation: sq2 0.2s 0.7s forwards;
    transform-origin: 50% top;
  }

  .errLoad .sq3 {
    position: absolute;
    width: 210px;
    height: 10px;
    transform: scaleX(0);
    background-color: rgb(154, 0, 185);
    top:80%;
    left: -2.5%;
    cursor: pointer;
    animation: sq3 0.2s 0.9s forwards;
    transform-origin: right 50%;
  }

  .errLoad .sq4 {
    position: absolute;
    width: 10px;
    height: 140px;
    transform: scaleX(0);
    background-color: rgb(154, 0, 185);
    top:15%;
    left: -7.5%;
    cursor: pointer;
    animation: sq4 0.2s 1.1s forwards;
    transform-origin: 50% bottom;
  }

  @keyframes sq1 {
    0% { transform: scaleX(0);}
    100% { transform: scaleX(1);}
  }

  @keyframes sq2 {
    0% { transform: scaleY(0);}
    100% { transform: scaleY(1);}
  }

  @keyframes sq3 {
    0% { transform: scaleX(0);}
    100% { transform: scaleX(1);}
  }

  @keyframes sq4 {
    0% { transform: scaleY(0);}
    100% { transform: scaleY(1);}
  }

  @keyframes apparition {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  </style>
