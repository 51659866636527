<template>
  <div class="about">
    <Loader/>
    <video playsinline autoplay muted loop id="bg2">
      <source src="../assets/bg2.mp4" type="video/mp4">
      Your browser does not support HTML5 video.
    </video>
    <div class="conteneur">
      <div class="container1">
        <div class="container-coffee">
          <div class="coffee-header">
            <div class="coffee-header__buttons coffee-header__button-one"></div>
            <div class="coffee-header__buttons coffee-header__button-two"></div>
            <div class="coffee-header__display"></div>
            <div class="coffee-header__details"></div>
          </div>
          <div class="coffee-medium">
            <div class="coffe-medium__exit"></div>
            <div class="coffee-medium__arm"></div>
            <div class="coffee-medium__liquid"></div>
            <div class="coffee-medium__smoke coffee-medium__smoke-one"></div>
            <div class="coffee-medium__smoke coffee-medium__smoke-two"></div>
            <div class="coffee-medium__smoke coffee-medium__smoke-three"></div>
            <div class="coffee-medium__smoke coffee-medium__smoke-for"></div>
            <div class="coffee-medium__cup"></div>
          </div>
          <div class="coffee-footer"></div>
        </div>
      </div>
      <div class="container2">
        <h1>Faites donc une pause...</h1>
        <p>
          Wordpress, Prestashop, Serveur, Backend, Frontend... Quel langage obscur... Pourquoi tant de mots <span class="strong">compliqués</span> alors que tout ce que vous voulez, au fond, c'est un site internet ?<br><br>
          Si vous êtes ici, c'est que vous cherchez à la fois un moyen <span class="strong">efficace</span>, <span class="strong">simple</span>, et <span class="strong">abordable</span> pour <span class="strong">imposer votre présence en ligne</span>. Et vous êtes au <span class="strong">bon endroit</span> !<br><br>
          <span class="strong">Vous avez un projet web ? Du site vitrine au projet e-commerce, laissez nous la partie compliquée, et faites donc une pause...</span> <br><br>
          Nous sommes là pour vous accompagner dans toutes les étapes de la vie de votre site internet. De son ébauche jusqu'à sa mise en ligne, et même la maintenance de votre site web. L'Agence Brome prendra soin d'étudier vos besoin, de concevoir, designer, et de developper votre projet, tout en vous conseillant... <br><br>
          <span class="strong">Votre projet de site web est entre de bonnes mains. Alors respirez, et faites donc une pause...</span>
        </p>
      </div>
    </div>
  </div>
</template>

<style>
#bg2 {
  position: fixed;
  right: 0;
  width: 100%;
  min-height: 100%;
  z-index: -1000;
  bottom: 0px;
}

.conteneur{
  position: absolute;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.container1{
  width: 40%;
  height: 400px;
  float: left;
}

.container2{
  width: 50%;
  float: right;
  padding: 20px;
  color: rgb(255, 255, 255);
}

.container2 h1{
  margin-bottom: 50px;
}

.container2 .strong{
  font-weight: bold;
}

/* MACHINE A CAFE ------------------------------------------------*/
.container-coffee {
  width: 300px;
  height: 280px;
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-50%,-50%);
}
.coffee-header {
  width: 100%;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ddcfcc;
  border-radius: 10px;
}
.coffee-header__buttons {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 25px;
  background-color: #282323;
  border-radius: 50%;
}
.coffee-header__buttons::after {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  bottom: -8px;
  left: calc(50% - 4px);
  background-color: #615e5e;
}
.coffee-header__button-one {
  left: 15px;
}
.coffee-header__button-two {
  left: 50px;
}
.coffee-header__display {
  width: 50px;
  height: 50px;
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  border-radius: 50%;
  background-color: rgb(191, 143, 201);
  border: 5px solid rgb(154, 0, 185);
  box-sizing: border-box;
}
.coffee-header__details {
  width: 8px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #9b9091;
  box-shadow: -12px 0 0 #9b9091, -24px 0 0 #9b9091;
}
.coffee-medium {
  width: 90%;
  height: 160px;
  position: absolute;
  top: 80px;
  left: calc(50% - 45%);
  background-color: #bcb0af;
}
.coffee-medium:before {
  content: "";
  width: 90%;
  height: 100px;
  background-color: #776f6e;
  position: absolute;
  bottom: 0;
  left: calc(50% - 45%);
  border-radius: 20px 20px 0 0;
}
.coffe-medium__exit {
  width: 60px;
  height: 20px;
  position: absolute;
  top: 0;
  left: calc(50% - 30px);
  background-color: #231f20;
}
.coffe-medium__exit::before {
  content: "";
  width: 50px;
  height: 20px;
  border-radius: 0 0 50% 50%;
  position: absolute;
  bottom: -20px;
  left: calc(50% - 25px);
  background-color: #231f20;
}
.coffe-medium__exit::after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: -30px;
  left: calc(50% - 5px);
  background-color: #231f20;
}
.coffee-medium__arm {
  width: 70px;
  height: 20px;
  position: absolute;
  top: 15px;
  right: 25px;
  background-color: #231f20;
}
.coffee-medium__arm::before {
  content: "";
  width: 15px;
  height: 5px;
  position: absolute;
  top: 7px;
  left: -15px;
  background-color: #9e9495;
}
.coffee-medium__cup {
  width: 80px;
  height: 47px;
  position: absolute;
  bottom: 0;
  left: calc(50% - 40px);
  background-color: #FFF;
  border-radius: 0 0 70px 70px / 0 0 110px 110px;
}
.coffee-medium__cup::after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 6px;
  right: -13px;
  border: 5px solid #FFF;
  border-radius: 50%;
}
@keyframes liquid {
  0% {
    height: 0px;
    opacity: 1;
  }
  5% {
    height: 0px;
    opacity: 1;
  }
  20% {
    height: 62px;
    opacity: 1;
  }
  95% {
    height: 62px;
    opacity: 1;
  }
  100% {
    height: 62px;
    opacity: 0;
  }
}
.coffee-medium__liquid {
  width: 6px;
  height: 63px;
  opacity: 0;
  position: absolute;
  top: 50px;
  left: calc(50% - 3px);
  background-color: #74372b;
  animation: liquid 4s 4s linear infinite;
}
.coffee-medium__smoke {
  width: 8px;
  height: 20px;
  position: absolute;
  border-radius: 5px;
  background-color: #b3aeae;
}
@keyframes smokeOne {
  0% {
    bottom: 20px;
    opacity: 0;
  }
  40% {
    bottom: 50px;
    opacity: .5;
  }
  80% {
    bottom: 80px;
    opacity: .3;
  }
  100% {
    bottom: 80px;
    opacity: 0;
  }
}
@keyframes smokeTwo {
  0% {
    bottom: 40px;
    opacity: 0;
  }
  40% {
    bottom: 70px;
    opacity: .5;
  }
  80% {
    bottom: 80px;
    opacity: .3;
  }
  100% {
    bottom: 80px;
    opacity: 0;
  }
}
.coffee-medium__smoke-one {
  opacity: 0;
  bottom: 50px;
  left: 102px;
  animation: smokeOne 3s 4s linear infinite;
}
.coffee-medium__smoke-two {
  opacity: 0;
  bottom: 70px;
  left: 118px;
  animation: smokeTwo 3s 5s linear infinite;
}
.coffee-medium__smoke-three {
  opacity: 0;
  bottom: 65px;
  right: 118px;
  animation: smokeTwo 3s 6s linear infinite;
}
.coffee-medium__smoke-for {
  opacity: 0;
  bottom: 50px;
  right: 102px;
  animation: smokeOne 3s 5s linear infinite;
}
.coffee-footer {
  width: 95%;
  height: 15px;
  position: absolute;
  bottom: 25px;
  left: calc(50% - 47.5%);
  background-color: rgb(154, 0, 185);;
  border-radius: 10px;
}
.coffee-footer::after {
  content: "";
  width: 106%;
  height: 26px;
  position: absolute;
  bottom: -25px;
  left: -8px;
  background-color: #000;
}

/*-----------------------------------------------------------------------------------*/

@media screen and (max-width: 1700px) {
  #bg2{
    width: 150%;
    min-width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  #bg2{
    width: initial;
  }
}

@media screen and (max-width: 999px) {
  .conteneur{
    position: inherit;
    width: 98%;
    top: 0;
    left: 0;
    transform:inherit;
  }

  .container1{
    width: 90%;
    float: inherit;
  }

  .container-coffee {
    top: 0;
    left: 50%;
    transform:inherit;
    transform: translate(-50%, 110px);
  }

  .container2{
    width: 90%;
    float: inherit;
    padding: 20px;
    color: rgb(255, 255, 255);
    margin-bottom: 30px;
  }

  /*.conteneur{
    top:30%;
    margin-bottom: 200px;
  }

  .container1 {
    width: 100%;
    margin-bottom: 450px;
  }

  .container2 {
    width: 90%;
    margin-top: 100px;
    padding-bottom: 30px;
  }

  .container-coffee {
    left: 50%;
  }*/
}
</style>

<script>
// @ is an alias to /src
import Loader from '@/components/Loader.vue'

export default {
  components: {
    Loader
  },
  mounted () {
    document.querySelector('.navCheckbox').checked = false
    setTimeout(
      function () {
        document.querySelector('#loader').style.display = 'none'
      }, 2300)
    window.scrollTo(0, 0)
  },
  methods: {
  }
}
</script>
